@import '../../../../styles/customMediaQueries.css';

.brandImages{
  margin: auto;
  margin-top: 3%;
  text-align: center;
  margin-bottom: 3%;
}
.esianSectiopn{
  width: 100%;
  margin: auto;
  text-align: left;

  @media (--viewportLarge) {
    width: 80%;
      }

}
.sliderSection{
    background:#3a5795;
    color: var(--colorWhite);

    @media (--viewportMedium) {
      display: block;
        }

    @media (--viewportLarge) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: start;
      justify-items: stretch;
    }
    
}
.sliderContent{
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & p{
    margin-top: 0;
  }
}
.buttonSlider{
  display: block;
  color: #fff !important;
  background-color: #ff5a5f !important;
  border-color: #ff5a5f !important;
  font-size: 16px;
  width: 180px;
  min-height: 44px;
}
.landingSubTItle{
 color: #252525;
 font-size: 2rem;
 text-align: center;
 margin: 56px 0;
 margin-top: 0px;
 font-weight: 500;
}
.sliderImage{
  background:#FFF url(./img/bgSliderImage.png) repeat-y top right;
  & img{
    width: 100%;
  }

  @media (--viewportLarge) {
    padding: 10%;
      }

}
.makeDifferent{
  font-weight: 500;
}
.buttonControls{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.buttonControls button{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: #3a5795 solid 1.5px;
  color: #3a5795;
  margin: 3px;
  cursor: pointer;
}
.buttonControls button:hover{
  background-color: #ff5a5f;
  color: #FFF;
}@import '../../../../styles/customMediaQueries.css';

.brandImages{
  margin: auto;
  margin-top: 3%;
  text-align: center;
  margin-bottom: 3%;
}
.esianSectiopn{
  width: 100%;
  margin: auto;
  text-align: left;

  @media (--viewportLarge) {
    width: 80%;
      }

}
.sliderSection{
    background:#3a5795;
    color: var(--colorWhite);

    @media (--viewportMedium) {
      display: block;
        }

    @media (--viewportLarge) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      justify-content: start;
      justify-items: stretch;
    }
    
}
.sliderContent{
  padding: 10%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  & p{
    margin-top: 0;
  }
}
.buttonSlider{
  display: block;
  color: #fff !important;
  background-color: #ff5a5f !important;
  border-color: #ff5a5f !important;
  font-size: 16px;
  width: 180px;
  min-height: 44px;
}
.landingSubTItle{
 color: #252525;
 font-size: 2rem;
 text-align: center;
 margin: 56px 0;
 margin-top: 0px;
 font-weight: 500;
}
.sliderImage{
  background:#FFF url(./img/bgSliderImage.png) repeat-y top right;
  & img{
    width: 100%;
  }

  @media (--viewportLarge) {
    padding: 10%;
      }

}
.makeDifferent{
  font-weight: 500;
}
.buttonControls{
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 15px;
}
.buttonControls button{
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: #3a5795 solid 1.5px;
  color: #3a5795;
  margin: 3px;
  cursor: pointer;
}
.buttonControls button:hover{
  background-color: #ff5a5f;
  color: #FFF;
}
.vedioSection{
    
    display: flex;
    align-content: center;
    
      
}